@import 'variables.css';
@import 'partials/_acier-font-face.css';

html.thesis {
	background-color: var(--blue-secondary);
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	min-height: 100%;
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23007297' fill-rule='evenodd'%3E%3Cellipse cx='14.5' cy='34.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='24.5' cy='34.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='34.5' cy='24.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='24.5' cy='24.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='14.5' cy='14.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='4.5' cy='14.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='4.5' cy='4.5' rx='2.5' ry='2.5'/%3E%3Cellipse cx='34.5' cy='4.5' rx='2.5' ry='2.5'/%3E%3C/g%3E%3C/svg%3E"); /* images/dot.svg (URI encoded) */
	background-size: 40px;

	@media (prefers-color-scheme: dark) {
		--text-color: var(--darkgrey);
	}
}


.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

main {
	animation: main-border 2s ease-out backwards;
	max-width: 20rem;
	text-align: center;
	font-family: monospace;
	background-color: white;
	box-shadow: inset 0 -5px 30px rgba(68, 68, 68, 0.1);
	padding: var(--space-l3);
	border: 5px solid #E6E8EB;

	.thesis & {
		background-color: #FBFBFB;
	}
}

.logo {
	width: 100%;
	height: auto;
	max-width: 5rem;
	margin-bottom: 2rem;
}

h1 {
	font: normal 8em/0.6 'acier-bat', var(--font-fam);
	color: var(--blue);
	margin: 0 auto var(--space-l2);

	.scott-shares & {
		font-size: 4.75em;
	}

	.thesis & {
		font-family: 'caslon-graphique', var(--font-fam);
		font-size: 3em;
		line-height: 1;
		color: var(--blue-secondary);
	}
}

p {
	margin: 0 auto var(--space-l2);
}

.home-link {
	font-size: 2em;
	text-decoration: underline;
}

@keyframes main-border {
	from {
		border: 5px solid rgba(230, 232, 235, 0);
		box-shadow: inset 0 -5px 30px rgba(68, 68, 68, 0);
	}
	50% {
		box-shadow: inset 0 -5px 30px rgba(68, 68, 68, 0);
	}
	top {
		border: 5px solid #E6E8EB;
		box-shadow: inset 0 -5px 30px rgba(68, 68, 68, 0.1);
	}
}
